import * as NGL from 'ngl/dist/ngl.js'

const RepresentationsSet = {
  _pqrs: [],

  toggle(index) {
    const pqr = this._pqrs[index];
    if (pqr) {
      pqr.toggleVisibility()
    }
  },

  addPqr(index, pqr) {
    this._pqrs.push(pqr.addRepresentation(
      "surface", {
        color: cavidb.palette.slice(1)[index],
        opacity: 0.6  , wireframe: true
       }));
    this.toggle(index);
  },

  clear() {
    this._pqrs = [];
  }
}


$(document).on("turbolinks:load", () => {
  RepresentationsSet.clear();
  const $root = $("#pdb-viewer");
  const $container = $(".cv-pdb-container");

  if ($root.length == 0) {
    return;
  }

  NGL.DatasourceRegistry.add(
    "cavidb", new NGL.StaticDatasource("./")
  );

  let stage = new NGL.Stage("pdb-viewer", { backgroundColor: 'white' });

  window.addEventListener('wheel', function (event) {
    if ($root[0].contains(event.target)) {
      event.preventDefault();
    }
  }, {passive: false});

  window.addEventListener("resize", function (event) {
    stage.handleResize();
  }, false);

  $container.addClass("cv-loading")

  Promise.all([`cavidb://${cavidb.currentChain._id}.pdb`]
    .concat(...cavidb.currentChain.pockets.map(it => `cavidb://${cavidb.currentChain._id}/pockets/${it.pocket_id}.pqr`))
    .map(it => stage.loadFile(it)))
    .then(function (items) {
      $container.removeClass("cv-loading")

      const pdb = items[0];
      const pqrs = items.slice(1);

      pdb.addRepresentation("cartoon", { color: "bfactor" });
      pqrs.forEach((pqr, index) => {
        RepresentationsSet.addPqr(index, pqr);
      });

      stage.mouseControls.remove('hoverPick')
      stage.mouseControls.add('hoverPick', (stage, pickingProxy) => {
        const tt = stage.tooltip;
        const sp = stage.getParameters();

        if (sp.tooltip && pickingProxy && pickingProxy.picker.structure.name.endsWith(".pqr")) {
          const mp = pickingProxy.mouse.position
          tt.innerText = "Cavity " + pickingProxy.picker.structure.name.replace(".pqr", "");
          tt.style.bottom = (window.innerHeight - mp.y + 3) + 'px'
          tt.style.left = (mp.x + 3) + 'px'
          tt.style.display = 'block'
        } else {
          tt.style.display = 'none'
        }
      });

      stage.mouseControls.remove("scroll");
      stage.mouseControls.add("scroll", NGL.MouseActions.zoomScroll);
      stage.autoView();
    })
})

export { RepresentationsSet }