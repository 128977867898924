import { StarsSet } from './stars'
import { RepresentationsSet } from './pdb-viewer'
import * as pockets from './pockets'

/**
 * @type {StarsSet}
 */
let stars = new StarsSet();

function createStars() {
  stars.create(cavidb.currentChain.stars)
}

function configureExpandControl() {
  $('#show-residue-details').on("click", () => {
    $('#pockets-viewer').toggleClass('cv-expanded-on');
  });
}

function configureControls($controls) {
  $controls.each((index, control) => {
    $(control).on("change", () => {
      previewPocket(control, false);
      togglePocket(index, control);
    })

    $(control).parent().on("mouseenter", () => {
      previewPocket(control, true);
    }).on("mouseleave", () => {
      previewPocket(control, false);
    })
  })
}

function configureResetControl($reset, $controls) {
  $reset.on("click", () => {
    const value = !$controls.first().prop("checked");
    $controls.each((_index, control) => {
      const $control = $(control);
      if ($control.prop("checked") !== value) {
        $control.prop("checked", value).trigger("change");
      }
    });
  })
}

function togglePocket(index, control) {
  const pocketId = control.getAttribute("data-pocket-id");
  const $pocketDescriptor = $(`#pocket-descriptor-${pocketId}`);

  $(`.cv-pockets-viewer .cv-residue-box.pocket-${pocketId}`).each((_index, residue) => {
    const $selected = $(residue).find(".cv-selected");
    residue.classList.toggle(`on-${pocketId}`)
    const count = Array.from(residue.classList).filter(it => it.startsWith("on-")).length;
    if (count !== 0) {
      $selected.html(`(${count})`);
    } else {
      $selected.html('')
    }
  });

  $pocketDescriptor.toggle();
  stars.toggle(index);
  RepresentationsSet.toggle(index);
}

function previewPocket(control, force = null) {
  const pocketId = control.getAttribute("data-pocket-id");
  $(`.cv-pockets-viewer .cv-residue-box.pocket-${pocketId}`).toggleClass('cv-preview', force);
}

document.addEventListener("turbolinks:load", () => {
  if (typeof (cavidb.currentChain) === "undefined" ||
    $("#pockets-viewer").length == 0) {
    return
  }
  const maxDigits = String(Math.max(...cavidb.currentChain.residues.map(it => it.position))).length;
  const sequence = cavidb.currentChain.residues.map(it => {
    const pocket_ids = it.pocket_ids;
    const cls = pocket_ids.map(pocket => `pocket-${pocket}`).join(" ");

    return `
            <div class="cv-residue-box cv-info ${cls}">
                <div class="cv-body">
                  <div class="cv-residue cv-compressed">
                    <div class="cv-aa">${it.aa_unicode}</div>
                  </div>
                  <div class="cv-residue cv-expanded" style="width: ${maxDigits + 3}ch">
                      <div class="cv-aa">${it.aa}<sub>${it.position}</sub></div>
                      <div class="cv-pockets-counter">${pocket_ids.length}<sub class="cv-selected"></sub></div>
                      <div class="cv-contacts-counter">${it.contacts_count}</div>
                  </div>
                </div>
                <div class="cv-tooltip">
                    Position: ${it.position}<br>
                    ${pockets.residueTooltips(pocket_ids)}
                </div>
            </div>`
  }).join("");

  document.getElementById("pockets-viewer").innerHTML = `
        <div class="sequence">
            ${sequence}
        </div>
        <form class="controls">
            ${pockets.createControls()}
        </form>
    `;

  createStars();

  const $controls = $("#pockets-viewer .control");
  const $druggableControls = $controls.filter(".cv-druggable")
  const $annotatedControls = $controls.filter(".cv-annotated")

  configureControls($controls);
  configureResetControl($("#reset-pocket-selection"), $controls);
  configureResetControl($("#reset-druggable-pocket-selection"), $druggableControls);
  configureResetControl($("#reset-annotated-pocket-selection"), $annotatedControls);
  configureExpandControl();
})
