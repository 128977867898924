
$(document).on("turbolinks:load", () => {
  const $searchButton = $("#search-button");
  const $searchInput = $("#q");

  const examples = $('.cv-q-example');

  function fillExample(li){
    const text = $(li).data('example');
    $searchInput.val(text).trigger("change");
  }

  $searchInput.on("change keyup keypress", () => {
    enableSearch();
  })

  function enableSearch() {
    $searchButton.prop('disabled', !$searchInput.val());
  }

  $(examples).on("click", (event) => {
    fillExample(event.target);
  })

  enableSearch();
});