$(document).on("turbolinks:before-cache", () => {
  $('.g-recaptcha').empty();
});

$(document).on("turbolinks:load", () => {
  const $token = $('#g-recaptcha-response-data-search');
  const $links = $('.cv-search-container');


  $links.each((index, it) => {
    $(it).addClass("cv-loading");
  });

  /** Token fetch */

  window.handleRecaptchaResponseTokenForSearch = function(id, token) {
    var element = document.getElementById(id);
    element.value = token;

    $links.each((index, it) => {
      $(it).removeClass("cv-loading");
    });
  }

  /** Token Refresh */

  let recaptchaPolling = setInterval(() => {
    executeRecaptchaForSearch();
  }, 1250 * 60);

  $(document).on('turbolinks:load', function clearPolling() {
    clearInterval(recaptchaPolling);
    $(document).off('turbolinks:load', clearPolling);
  });

  /** Token attachment */

  $links.find('a').on('click', function(e) {
    const value = $token.val();
    e.originalEvent.currentTarget.href += "&g-recaptcha-response-data[search]=" + value;
  });
});