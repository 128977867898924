function targetPrefix(target) {
  if (target == 'currentChain') return '';
  if (target == 'leftChain') return 'left-';
  return 'right-';
}

function createControls(target = 'currentChain', type = 'checkbox') {
  const prefix = targetPrefix(target);
  const pockets = cavidb[target].pockets;

  if (pockets.length === 0) {
    return "";
  }
  const extraDigits = cavidb[target].annotated ? 2 : 0;
  const maxDigits = String(pockets[pockets.length - 1].pocket_id).length + extraDigits;
  return pockets.map(it => it.pocket_id).map(it => {
    const controlId = `${prefix}control-${it}`;
    let icons = [];
    let classes = [];

    if (cavidb[target].isDruggable(it)) {
      icons.push('<i class="fas fa-prescription-bottle-alt"></i>');
      classes.push('cv-druggable');
    }

    if (cavidb[target].isAnnotated(it)) {
      icons.push('<i class="fas fa-sticky-note"></i>');
      classes.push('cv-annotated');
    }

    return `
        <fieldset>
            <input type="${type}" name="${target}" id="${controlId}" class="control ${classes.join(' ')}" data-pocket-id="${it}">
            <label class="cv-label cv-pocket-${it}" style="width: ${maxDigits + 8}ch" for="${controlId}">Cavity ${it} ${icons.join(' ')}</label>
        </fieldset>
        `
  }).join("")
}

function residueTooltips(pocketIds, prefix = '') {
  let pocketLabels = pocketIds.map(it => `<span class="cv-pocket-box cv-pocket-${it}"></span> ${it}`);
  if (pocketLabels.length == 0) {
    return '';
  } else if (pocketLabels.length == 1) {
    return [prefix, 'Cavity:', pocketLabels[0], '<br/>'].join(' ');
  } else {
    return [prefix, 'Cavities:', pocketLabels.join(", "), '<br/>'].join(' ');
  }
}

export {
  createControls,
  residueTooltips,
  targetPrefix
}