import { Chart, LinearScale, CategoryScale } from 'chart.js';
import { BoxPlotController, BoxAndWiskers } from '@sgratzl/chartjs-chart-boxplot';

Chart.register(BoxPlotController, BoxAndWiskers, LinearScale, CategoryScale);

function renderResiduesPerPocketPlot(canvas) {
  const $canvas = $(canvas);
  const descriptorId = $canvas.data('descriptor-id');
  const target = $canvas.data('target');

  const boxplotData = {
    labels: ['Cavity'],
    datasets: cavidb[target].residuesPerPockets.map(it => {
      return {
        label: `Cavity ${it.pocket_id}`,
        backgroundColor: it.color,
        borderWidth: 1,
        padding: 10,
        itemRadius: 0,
        data: [it.residues.map(it => it[descriptorId])]
      };
    })
  };

  new Chart(canvas, {
    type: 'boxplot',
    data: boxplotData,
    options: {
      plugins: { legend: { display: false } }
    },
  });
}

$(document).on("turbolinks:load", () => {
  $(".cv-residues-per-pocket-boxplot").each((_index, canvas) => {
    renderResiduesPerPocketPlot(canvas)
  })
});