
import { Chart } from "chart.js"
import Color from "color"

class Star {
    /**
     *
     * @param {object} data
     */
    constructor(data, prefix, type) {
        this.type = type;
        this.data = data;
        this.borderColor = new Color(data.color).darken(0.3).string();
        this.chart = null;
        this.visible = false;
        this.prefix = prefix;
    }

    get pocketId() {
        return this.data.pocket_id;
    }

    become(type) {
        this.type = type;
        if (this.visible) {
            this.redraw();
        }
    }

    redraw() {
        this.hide();
        this.show();
    }

    show() {
        const $canvas = $(`#${this.prefix === '' ? '' : this.prefix + '-'}pocket-star-${this.pocketId}`);
        const data = {
            labels: this.type.labels(),
            datasets: [{
                label: `Cavity ${this.pocketId}`,
                data: this.type.datapoints(this.data),
                fill: true,
                backgroundColor: this.data.color,
                borderColor: this.borderColor,
                pointBackgroundColor: this.borderColor,
                pointHoverBorderColor: this.borderColor
            }]
        };
        const config = {
            type: "radar",
            data: data,
            options: {
                scales: {
                    r: {
                        ticks: { display: false },
                        suggestedMin: 0,
                        suggestedMax: 1
                    }
                },
                scale: { ticks: { maxTicksLimit: 3 } }
            },
        };

        this._createRadar($canvas[0], config)
    }

    hide() {
        this.chart.destroy();
        this.visible = false;
    }

    toggle(force = null) {
        if ((force === true || force === null) && !this.visible) {
            this.show();
        } else if ((force === false || force === null) && this.visible) {
            this.hide();
        }
    }

    _createRadar(canvas, config) {
        const chart = new Chart(canvas, config);
        chart.canvas.parentNode.style.height = "300px";
        chart.canvas.parentNode.style.width = "300px";
        chart.legend.options.display = false;
        this.chart = chart;
        this.visible = true;
    }
}

class PocketStarType {
    labels() {
        return [
            "Relative length",
            "Mean net charge",
            ["Absolute", "NCPR"],
            ["Positive AA", "fraction"],
            "Complexity",
            ["AA promoting", "disorder", "fraction"],
            "Hydropathy",
            ["Negative AA", "fraction"],
            ["ASA"],
            ["FCR"]
        ];
    }

    datapoints(data) {
        return [
            data.relative_length,
            data.mean_net_charge,
            data.absolute_ncpr,
            data.fraction_of_positive_residues,
            data.complexity,
            data.fraction_of_aa_promoting_disorder_residues,
            data.hydropathy,
            data.fraction_of_negative_residues,
            data.ASA,
            data.fcr
        ];
    }
}

class StarsSet {
    constructor(prefix = "") {
        /** @type {Array<Star>} */
        this.stars = [];
        this.prefix = prefix;
    }

    create(data) {
        this.stars = data.map(it => new Star(it, this.prefix, new PocketStarType()));
    }

    toggle(index, force = null) {
        this.stars[index].toggle(force);
    }
}

export { StarsSet, Star, PocketStarType }