import * as d3 from 'd3'

function configureTicks(axis, band) {
  const domain = band.domain();
  if (domain.length <= 20) {
    return axis;
  }

  let cut;
  if (domain.length > 100) {
    cut = 10;
  } else if (domain.length > 50) {
    cut = 5;
  } else {
    cut = 2;
  }
  return axis.tickValues(domain.filter((d, i) => !((i + 1) % cut)));
}

function renderHeatMap({ xAxis, yAxis, data, root, tooltipHtml, tooltipClass, width }) {
  const maxValue = Math.max(...data.map(it => it.value));
  const rootElement = document.querySelector(root);

  const margin = { top: 25, right: 25, bottom: 30, left: 40 },
    realWidth = width - margin.left - margin.right,
    realHeight = width - margin.top - margin.bottom;

  // append the svg object to the body of the page
  const svg = d3.select(root)
    .append("svg")
    .attr("width", realWidth + margin.left + margin.right)
    .attr("height", realHeight + margin.top + margin.bottom)
    .append("g")
    .attr("transform", `translate(${margin.left}, ${margin.top})`);

  const x = d3.scaleBand()
    .range([0, realWidth])
    .domain(xAxis)
    .padding(0.05);
  svg.append("g")
    .style("font-size", 15)
    .attr("transform", `translate(0, ${realHeight})`)
    .call(configureTicks(d3.axisBottom(x).tickSize(0), x))
    .select(".domain").remove()

  // Build Y scales and axis:
  const y = d3.scaleBand()
    .range([realHeight, 0])
    .domain(yAxis)
    .padding(0.05);
  svg.append("g")
    .style("font-size", 15)
    .call(configureTicks(d3.axisLeft(y).tickSize(0), y))
    .select(".domain").remove()

  // Build color scale
  const myColor = d3.scaleSequential()
    .range(["#fff", "#9e0142"])
    .domain([0, maxValue])

  // create a tooltip
  const tooltip = d3.select(root)
    .append("div")
    .style("display", "none")
    .attr("class", tooltipClass)

  // Three function that change the tooltip when user hover / move / leave a cell
  const mouseover = function (event, d) {
    tooltip
      .style("display", "block")
    d3.select(this)
      .style("stroke", "#520122")
      .style("opacity", 1)
  }
  const mousemove = function (event, d) {
    tooltip
      .html(tooltipHtml(d))
      .style("left", (window.scrollX + event.x) + "px")
      .style("top", (window.scrollY + event.y + 15) + "px")
  }
  const mouseleave = function (event, d) {
    tooltip
      .style("display", "none")
    d3.select(this)
      .style("stroke", "none")
      .style("opacity", 0.8)
  }

  // add the squares
  svg.selectAll()
    .data(data, function (d) { return d.x + ':' + d.y; })
    .join("rect")
    .attr("x", function (d) { return x(d.x) })
    .attr("y", function (d) { return y(d.y) })
    .attr("rx", 4)
    .attr("ry", 4)
    .attr("width", x.bandwidth())
    .attr("height", y.bandwidth())
    .style("fill", function (d) { return myColor(d.value) })
    .style("stroke-width", 2)
    .style("stroke", "none")
    .style("opacity", 0.8)
    .on("mouseover", mouseover)
    .on("mousemove", mousemove)
    .on("mouseleave", mouseleave)
}

export { renderHeatMap };