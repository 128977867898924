// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import 'chartkick/chart.js'
import 'jquery'
import 'channels'
import './tabs'
import './pdb-viewer'
import './pockets-viewer'
import './pockets-diff-viewer'
import './residues-per-pockets-viewer'
import './contacts-viewer'
import './search'
import './diff'
import './recaptcha'

window.cavidb = {};

Rails.start()
Turbolinks.start()