
$(document).on("turbolinks:load", () => {
  const $diffButton = $("#diff-button");

  $('input[name=l], input[name=r]').on("change", () => {
    enableDiff();
  })

  $('input[name=r]').on("change", (e) => {
    const id = $(e.target).data('chain-id')
    $('input[name=l]').each((index, it) => {
      const $it = $(it);
      $it.toggleClass('cv-hidden', $it.data('chain-id') === id)
    })
  })


  function enableDiff() {
    $diffButton.prop('disabled', $('input[name=l]:checked, input[name=r]:checked').length !== 2);
  }

  enableDiff();
});