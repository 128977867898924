import { ForceGraph } from './vendor/force-graph'
import { renderHeatMap } from './vendor/heatmap'
import * as d3 from 'd3'

/**
 *
 * @param {HTMLElement} root
 */
function renderContactsGraph(root, width) {
    function buildLinks(bond) {
        return cavidb.currentChain.contacts[`${bond}Bond`].map(it => ({ source: it.from_pocket_id, target: it.to_pocket_id, value: it.energy }))
    }
    const contacts = {
        nodes: cavidb.currentChain.pockets.map(it => ({ id: it.pocket_id, group: it.pocket_id })),
        links: buildLinks('bbh').concat(...buildLinks('sch')).concat(...buildLinks('coulombic'))
    }
    const chart = ForceGraph(contacts, {
        nodeId: d => d.id,
        nodeGroup: d => d.group,
        nodeTitle: d => `Cavity ${d.id}`,
        linkStrokeWidth: l => 2,
        width,
        height: width,
        nodeRadius: 10,
        colors: cavidb.palette.slice(1),
        nodeStroke: d => cavidb.currentChain.isDruggable(d.id) ? '#000' : '#fff'
    })
    $(root).append(chart);
}

function renderContactsHeatMap({pockets, contacts, root, width}) {
    renderHeatMap({
        root,
        width,
        xAxis: pockets,
        yAxis: pockets,
        data: contacts.map(it => ({
            x: it.from_pocket_id,
            y: it.to_pocket_id,
            value: it.energy,
            details: it.pairs
        })),
        tooltipClass: 'cv-tooltip',
        tooltipHtml: (d) => {
            return `
                    <strong>
                        <span class="cv-pocket-box cv-pocket-${d.x}"></span> Cavity ${d.x} →
                        <span class="cv-pocket-box cv-pocket-${d.y}"></span> Cavity ${d.y}
                    </strong><br>` + d
                .details
                .map(it => `
                    <span class="cv-residue">${it.from_aa}<sub>${it.from_position}</sub></span> →
                    <span class="cv-residue">${it.to_aa}<sub>${it.to_position}</sub></span> :
                    ${it.energy}`)
                .join("<br>")
        }
    });
}

$(document).on("turbolinks:load", () => {
    const $roots = $(".cv-contacts-descriptor");
    if (!$roots.length) return;

    const pockets = cavidb.currentChain.pockets.map(it => it.pocket_id);
    renderContactsGraph($("#contacts-plot")[0], $roots[0].clientWidth);

    const heatMapWith = $roots[1].clientWidth;
    renderContactsHeatMap({
        pockets,
        width: heatMapWith,
        root: "#bbh-contacts-heatmap",
        contacts: cavidb.currentChain.contacts.bbhBond
    });

    renderContactsHeatMap({
        pockets,
        width: heatMapWith,
        root: "#sch-contacts-heatmap",
        contacts: cavidb.currentChain.contacts.schBond
    });

    renderContactsHeatMap({
        pockets,
        width: heatMapWith,
        root: "#coulombic-contacts-heatmap",
        contacts: cavidb.currentChain.contacts.coulombicBond
    });


});
