import { StarsSet } from './stars'
import * as pockets from './pockets'

/**
 * @type {StarsSet}
 */
let rightStars = new StarsSet('right');
let leftStars = new StarsSet('left');

function createStars() {
  rightStars.create(cavidb.rightChain.stars);
  leftStars.create(cavidb.leftChain.stars);
}

function configureControls(target, $controls, stars) {
  $($controls).on("change", () => {
    $controls.find('.control').each((index, control) => {
      previewPocket(target, control, false);
      togglePocket(target, index, control, stars);
    })
  })

  $controls.find('.control').each((index, control) => {
    $(control).parent().on("mouseenter", () => {
      previewPocket(target, control, true);
    }).on("mouseleave", () => {
      previewPocket(target, control, false);
    })
  })
}

/**
 *
 * @param {*} target
 * @param {*} index
 * @param {*} control
 * @param {StarsSet} stars
 */
function togglePocket(target, index, control, stars) {
  const pocketId = control.getAttribute("data-pocket-id");
  const $pocketDescriptor = $(`#${target}-pocket-descriptor-${pocketId}`);

  $(`.cv-pockets-viewer .cv-residue-box.${target}-pocket-${pocketId} .cv-${target}-diff.cv-resnum`)
    .toggleClass(`on-${pocketId}`, control.checked);

  $pocketDescriptor.toggle(control.checked);
  stars.toggle(index, control.checked);
}

function previewPocket(target, control, force = null) {
  const pocketId = control.getAttribute("data-pocket-id");
  $(`.cv-pockets-viewer .cv-residue-box.${target}-pocket-${pocketId}`).toggleClass('cv-preview', force);
}


function pocketIdsClasses(residue, target) {
  return readSide(residue, target, 'pocket_ids', []).map(pocket => `${target}-pocket-${pocket}`);
}

function readSide(residue, target, field, ifAbsent = null) {
  const targetResidue = residue[1][target];
  if (targetResidue) {
    return targetResidue[field];
  } else {
    return ifAbsent;
  }

}

document.addEventListener("turbolinks:load", () => {
  if (typeof (cavidb.leftChain) === "undefined" ||
    $("#pockets-diff-viewer").length == 0) {
    return
  }

  const maxDigits = String(Math.max(...cavidb.currentDiff.residues.map(it => it[0]))).length * 2 * 0.65;
  const sequence = cavidb.currentDiff.residues.map(it => {
    const cls = pocketIdsClasses(it, 'left').concat(pocketIdsClasses(it, 'right')).join(" ");

    return `
            <div class="cv-residue-box cv-info ${cls}">
                <div class="cv-body">
                  <div class="cv-residue cv-expanded" style="width: ${maxDigits + 3}ch">
                      <div class="cv-aa">${(it[1].left || it[1].right)['aa']}<sub>${it[0]}</sub></div>
                      <div class="cv-resnum cv-left-diff">${readSide(it, 'left', 'position', '- ')}</div>
                      <div class="cv-resnum cv-right-diff">${readSide(it, 'right', 'position', '-')}</div>
                  </div>
                </div>
                <div class="cv-tooltip">
                    Position: ${it[0]}<br>
                    ${pockets.residueTooltips(readSide(it, 'left', 'pocket_ids', []), cavidb.leftChain.id)}
                    ${pockets.residueTooltips(readSide(it, 'right', 'pocket_ids', []), cavidb.rightChain.id)}
                </div>
            </div>
          `
  }).join("");

  document.getElementById("pockets-diff-viewer").innerHTML = `
        <form class="controls cv-left-diff">
            ${pockets.createControls('leftChain', 'radio')}
        </form>
        <div class="sequence">
        ${sequence}
        </div>
        <form class="controls cv-right-diff">
          ${pockets.createControls('rightChain', 'radio')}
        </form>
    `;

  createStars();

  configureControls(
    'left',
    $("#pockets-diff-viewer .controls.cv-left-diff "),
    leftStars);

  configureControls(
    'right',
    $("#pockets-diff-viewer .controls.cv-right-diff "),
    rightStars);
})
