function toggleTab($control) {
  const $tab = $(`#${$control.data('tab-id')}`);
  $tab.toggle($control.prop("checked"));
}

function toggleTabs() {
  $(".cv-tab").each((index, control) => {
    toggleTab($(control));
  })
}

$(document).on("turbolinks:load", () => {
  $(".cv-tab").each((index, control) => {
    $(control).on("change", () => {
      toggleTabs();
    })
  })
  toggleTabs();
});